.userList > span.MuiListItemText-primary {
  font-size: 18px;
}

.appBarBtn > span {
  font-weight: bold;
  font-size: 19px;
  color: white;
}

button:disabled > span {
color:  #bbb9b9;
}

/* button > span {
  font-size: 17px;
} */

modal-projList > span.MuiListItemText-primary{
  font-size: 1.5rem;
}

modal-projList > p.MuiListItemText-secondary{
  font-size: 18px;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input[type = text], input[type = password]  {
    text-align: center;
}


.react-datepicker-popper {
  z-index: 3;
}


.datepicker-input {
    border: 1px solid #ccc;
    padding: 8px;
    font-size: 19px;
    letter-spacing: 2px;
    justify-content: center;
    width: 130px;
    vertical-align: middle;
    text-align: center;
    margin-bottom: 2px;
}

.react-datepicker {
    font-size: 1.3em;
}

.react-datepicker__header {
    padding-top: 1em;
}


.react-datepicker__month {
    margin: 0.4em 1em;
}

.react-datepicker__day-name, .react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
}

.react-datepicker__current-month {
    font-size: 1em;
}

.react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
}

.react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
}

.react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
}

.undraggable {
    -webkit-user-drag: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}